<template>
  <div v-cloak>
    <div v-if="apiLoaded || errorText">
      <div v-if="apiLoaded" class="wrap">
        <a @click="$router.go(-1)" class="back-link">
          <v-icon class="icon-item icon-chevron-left"></v-icon>
          Назад
        </a>
        <h1>Настройка чек-листов подсистемы «Индекс клиентоцентричности СЗН»</h1>

        <table class="config-list-table">
          <thead>
          <tr>
            <th>Id конфигурации</th>
            <th>Наименование</th>
            <th>Дата создания</th>
            <th>Дата изменения</th>
            <th>Статус</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="config in configs" :key="config.id">
            <td>{{ config.id }}</td>
            <td>{{ config.configParams.title }}</td>
            <td>{{ config.createdDate | dateFormat }}</td>
            <td v-if="config.modifiedDate">{{ config.modifiedDate | dateFormat }}</td>
            <td v-else>–</td>
            <td v-if="config.active">
              <div style="color: darkgreen;">Активный</div>
              <a href="#" @click.stop="changeActivity('toInactive', config.id)">Сделать неактивным</a>
            </td>
            <td v-else>
              <div>Неактивный</div>
              <a href="#" @click.stop="changeActivity('toActive', config.id)">Сделать активным</a>
            </td>
            <td style="width: 10px">
              <div class="edit" title="Просмотр данных индекста клиентоцентричности СЗН по данной конфигурации">
                <router-link :to="'/clientCentricity/'+config.id">
                  <v-icon class="icon-item icon-briefcase-eye-outline"></v-icon>
                </router-link>
              </div>
            </td>
            <td style="width: 10px">
              <div  class="edit" title="Редактирование конфигурации"> <!-- v-if="!config.active" -->
<!--                <router-link :to="'/config/clientCentricityConfigEdit/'+config.id">-->
                <v-icon class="icon-item icon-note-edit-outline"></v-icon>
<!--                </router-link>-->
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="save-buttons">
          <v-btn color="primary" @click="$router.push('/config/clientCentricityConfigEdit/')">Создать конфигурацию</v-btn>
        </div>

      </div>
      <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
        Ошибка: {{ errorText }}
      </div>
      <div v-else style="height:1000px"></div>
    </div>
  </div>
</template>

<script>
import api from "@/modules/api";

export default {
  name: "ClientCentricityConfig",
  data() {
    return {
      configs: [],
      apiLoaded: false,
      errorText: null,
      apiFreeze: false //уже ушел запрос апи - нужно дождаться его окончания, прежде чем слать новые запросы
    };
  },
  methods: {
    async changeActivity(status, configId) {
      alert(status + configId)
      if (this.apiFreeze) {
        return
      }
      this.apiFreeze = true;
      let req,
          obj = {configId: Number(configId)};
      if (status === 'toActive') {
        obj.isActive = true;
      } else if (status === 'toInactive') {
        obj.isActive = false;
      }
      req = await api.get("/clientCentricity/config/activateConfig", {id : configId});
      if (req.ok) {
        await this.getData()
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.apiFreeze = false
    },

    async getData() {
      let req = await this.$getApi("/clientCentricity/config/getList")
      if (req.ok) {
        this.configs = req.payload
        // console.log('this.configs', this.configs)
        this.sortConfigsById()

        for (let config of this.configs) {
          config.configParams = JSON.parse(config.clientCentricityJson)
        }

        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    sortConfigsById() {
      //больший id - в начало
      this.configs.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.getData()
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
.config-list-table {
  border-collapse: collapse;
  a {
    font-size: 14px;
  }
}
</style>